@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');

:root {
  --light-primary-01: #5C42E5;

  --light-secondary-01: #FFCC33;
  
  --light-neutral-01: #FFFFFF;
  --light-neutral-02: #191919;

  --light-ui-01: #0E111D;
  --light-ui-02: #595959;
  --light-ui-03: #999999;
  --light-ui-04: #D9D9D9;
  --light-ui-05: #F2F2F2;

  --light-text-01: #191919;
  --light-text-02: #4C4C4C;
  --light-text-03: #767676;
  --light-text-04: #999999;
  --light-text-05: #FFFFFF;
}

* {
  font-family: 'Source Sans 3', sans-serif;
}

html {
  overscroll-behavior: none;
}

body {
  background-color: #131312;
  color: #fbfafb;
  margin: 0;
  padding: 0;
  overscroll-behavior: none;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

/****** Animations *****/
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate__fade-in {
  animation-name: fadeIn;
  animation-duration: 650ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@for $i from 1 through 20 {
  $delay: $i * 100ms;
  .animate__delay-#{$i * 100}ms {
    -webkit-animation-delay: $delay;
    animation-delay: $delay;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.animate__rotate {
  animation-name: rotate;
  animation-duration: 650ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

/****** Components *****/

.page {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  .content {
    display: flex;
    flex-direction: column;
    max-width: 1248px;
    width: calc(100% - 32px);
    padding: 12px 16px;
    .title {
      font-size: 32px;
      font-weight: 500;
      margin: 24px 0 12px 0;
    }
    .subtitle {
      font-size: 16px;
      font-weight: 400;
      color: #a0a1a1;
      margin: 0;
      padding: 0 0 16px 0;
    }
    .panel {
      background-color: #212121;
      border-radius: 8px;
      padding: 24px;
      width: 100%;
      &.clear {
        background-color: transparent;
      }
      .title {
        font-size: 14px;
        font-weight: 500;
        color: #e9e9e9;
        margin: 0;
        padding: 0 0 16px 0;
      }
      .text {
        font-size: 13px;
        font-weight: 400;
        color: #a6a6a6;
        margin: 0;
        padding: 0 0 16px 0;
        &.right {
          text-align: right;
        }
      }
    }
  }
}

.textarea {
  background-color: #212121;
  border: 1px solid #565a64;
  border-radius: 8px;
  box-sizing: border-box;
  color: #e9e9e9;
  padding: 12px;
  &:focus {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 4px rgba(255, 255, 255, 0.6);
    outline: 0 none;
    transition: 150ms;
  }
}

.button {
  align-items: center;
  background-color: #f99d7b;
  border-radius: 8px;
  border: none;
  color: #041e49;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  height: 52px;
  justify-content: center;
  line-height: 26px;
  text-decoration: none;
  &:hover {
    background-color: #f8ab8f;
    cursor: pointer;
    transition: 150ms;
  }
  &:disabled {
    background-color: #565a64;
    cursor: initial;
  }
  &.secondary {
    background-color: transparent;
    border: 1px solid #565a64;
    color: #f29686;
    &:hover {
      border: 1px solid #8f6956;
      background-color: #2d2927;
      transition: 150ms;
    }
  } 
  .material-symbols-outlined {
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
  }
}
